<template>
  <div>
    <template v-if="showMenu">
      <p>二级页面</p>
      <ul>
        <li>
          <router-link to="/exception/second/101"> 101</router-link>
        </li>
        <li>
          <router-link to="/exception/second/102"> 102 </router-link>
        </li>
        <li>
          <router-link to="/exception/second/103"> 103 </router-link>
        </li>
      </ul>
    </template>
    <template v-else>
      <p @click="goback">返回上一页面</p>
    </template>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showMenu: true,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == "/exception/second/104") {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
      console.log(to, from);
    },
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
