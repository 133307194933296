var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showMenu
        ? [
            _c("p", [_vm._v("二级页面")]),
            _c("ul", [
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/exception/second/101" } },
                    [_vm._v(" 101")]
                  )
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/exception/second/102" } },
                    [_vm._v(" 102 ")]
                  )
                ],
                1
              ),
              _c(
                "li",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/exception/second/103" } },
                    [_vm._v(" 103 ")]
                  )
                ],
                1
              )
            ])
          ]
        : [_c("p", { on: { click: _vm.goback } }, [_vm._v("返回上一页面")])],
      _c("router-view")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }